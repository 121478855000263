.shop-my {
  background: var(--white);

  &-grid {
    display: grid;
    grid-template-areas:
      'ttl'
      'slider'
      'nav';
    gap: 24px;
    align-items: center;

    @include breakpoint(medium) {
      grid-template-areas:
        'empty ttl nav'
        'slider slider slider';
      grid-template-columns: 80px 1fr 80px;
    }

    @include breakpoint(large) {
      gap: 32px 24px;
    }
  }

  &-ttl {
    margin: 0;
    grid-area: ttl;
    text-align: center;
  }

  &-slider {
    grid-area: slider;
    min-width: 0;
  }

  &-nav {
    grid-area: nav;
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;

    @include breakpoint(medium) {
      justify-content: flex-end;
    }

    &-btn {
      display: flex;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
