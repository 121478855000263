.text-img {
  display: flex;
  gap: 40px;
  &.dr-reverse {
    flex-direction: row-reverse;
    @include breakpoint(medium down) {
      flex-direction: column;
    }
  }
  &:not(:last-child) {
    margin-bottom: 128px;
    @include breakpoint(medium down) {
      margin-bottom: 62px;
    }
    @include breakpoint(small down) {
      margin-bottom: 42px;
    }
  }
  @include breakpoint(medium down) {
    flex-direction: column;
    gap: 24px;
  }
  &-img {
    min-width: 374px;
    height: 284px;
    border-radius: var(--radius);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--surface);
    padding: 18px;
    @include breakpoint(small down) {
      min-width: 100%;
      height: 165px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
    @include breakpoint(small down) {
      font-size: 20px;
    }
  }
  &-text {
    font-size: 16px;
    a {
      font-weight: 600;
      color: var(--secondary-color);
      text-decoration: underline;
    }
  }
  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li.item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      text-transform: uppercase;
      margin-bottom: 8px;
      flex-wrap: wrap;
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--primary-color);
        display: block;
      }
      span {
        font-weight: 700;
      }
      a {
        font-weight: 500;
      }
      @include breakpoint(small down) {
        font-size: 14px;
      }
    }
    .socials {
      gap: 8px;
    }
    .socials-item a {
      width: 28px;
      height: 28px;
      padding: 5px;
      border-radius: 8px;
    }
  }
}
