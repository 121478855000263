.accordion {
  list-style: none;
  margin: 1.5rem 0;
  padding: 0;
  display: grid;
  gap: 12px;

  &-item {
    background: var(--white);
    border-radius: var(--radius);
    box-shadow: var(--shadow);
  }

  &-ttl {
    font: 600 15px/1.3 var(--title-font-family);
    margin: 0;

    @include breakpoint(medium) {
      font-size: 20px;
    }
  }

  &-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    text-decoration: none;
    color: var(--black);
    padding: 16px 24px;

    svg {
      transition: transform var(--speed-fast);

      .is-active & {
        transform: rotate(180deg);
      }
    }
  }

  &-cont {
    display: none;
    padding: 0 24px 16px;
    color: var(--dark-grey);
    font-size: 16px;
  }
}

[data-accordion-content] {
  display: none;

  .is-active & {
    display: block;
  }
}
