.dash-values{
  background: var(--white);
  padding: 48px 0;

  @include breakpoint(medium down) {
    padding: 42px 0;
  }

  @include breakpoint(small down) {
    padding: 32px 0;
  }

  &-video{
    width: 100%;
    padding-top: 24px;

    @include breakpoint(medium down) {
      padding-top: 0;
      width: 47%;
    }

    @include breakpoint(small down) {
      width: 100%;
    }

    &-ttl{
      font-size: 24px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 24px;

      @include breakpoint(medium only) {
       display: none;
      }

      @include breakpoint(small down) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }

  &-flex{
    display: flex;
    flex-wrap: wrap;
    gap: 32px 24px;
    padding-top: 24px;
    justify-content: center;

    @include breakpoint(medium down) {
      gap: 32px 40px;
      justify-content: space-between;
    }

    @include breakpoint(small down) {
      flex-direction: column;
      gap: 24px;
    }
  }

  &-card{
    width: 384px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(medium down) {
      width: 47%;
    }

    @include breakpoint(small down) {
      width: 100%;
    }

    img{
      height: 105px;
      width: auto;
    }

    &-ttl{
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 700;
      max-width: 300px;

      @include breakpoint(medium down) {
        font-size: 23px;
      }

      @include breakpoint(small down) {
        font-size: 16px;
        font-weight: 800;
      }
    }

    &-box{
      background: #F3EBE9;
      color: var(--black);
      font-size: 16px;
      font-weight: 400;
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      flex: 1;
      text-align: center;
      width: 100%;
      border-radius: 10px;
    }
  }
}