.footer {
  background: var(--dark-grey);
  color: var(--white);
  text-align: center;
  margin-top: auto;

  &-area {
    padding-top: 23px;
    display: grid;
    gap: 24px;
  }
  &-title {
    font-size: 40px;
    font-weight: 700;
    font-family: var(--title-font-family);
    text-transform: uppercase;
    @include breakpoint(medium down) {
      font-size: 36px;
    }
    @include breakpoint(small down) {
      font-size: 26px;
    }
  }
  &-link {
    color: var(--yellow);
    font-size: 18px;
    text-decoration: none;
    font-weight: 600;
    @include breakpoint(medium down) {
      font-size: 20px;
    }
    &:hover {
      color: var(--yellow);
    }
  }

  &-copy {
    font-size: 14px;
    padding: 24px;
  }
}
