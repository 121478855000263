.tabs {
  list-style: none;
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  gap: 0;
  justify-content: center;

  @include breakpoint(medium) {
    margin: 1.5rem 0;
  }

  &-ttl {
    border: 1px solid var(--primary-color);
    display: block;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 11px 14px;
    background: var(--white);
    color: var(--black);
    text-decoration: none;
    transition: all var(--speed-fast);
    border-radius: 0;

    @include breakpoint(small down) {
      font-size: 12px;
      padding: 12px 10px;
    }

    @media (max-width: 359px) {
      font-size: 11px;
      padding: 12px 5px;
    }

    &:hover {
      background: var(--white);
      color: var(--black);
    }

    &.is-active {
      background: var(--primary-color);
      box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.21);
      color: var(--white);
    }

    &.secondary {
      border-color: var(--secondary-color);
      border-color: var(--secondary-color);
      color: var(--secondary-color);

      &.is-active {
        background: var(--secondary-color);
        color: var(--on-secondary-color);
      }
    }

    &.small {
      font: 600 10px/12px var(--body-font-family);
    }
  }

  li {
    &:first-child {
      a {
        border-radius: 6px 0 0 6px;
      }
    }

    &:last-child {
      a {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  &-panel {
    margin: 1rem;

    @include breakpoint(medium) {
      margin: 1.5rem 0;
    }
  }
}

[data-tabs-itm]:not(.is-active) {
  display: none;
}
