.socials {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 24px;
}
.socials-item {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--yellow);
    border: 1px solid var(--black);
    box-shadow: 0px 2px 0px var(--black);
    border-radius: var(--radius);
    width: 55px;
    height: 55px;
    transition: all var(--speed-fast);
    padding: 10px;
    &:hover {
      border-color: var(--black);
      transform: translateY(3px);
      box-shadow: var(--black-shadow-hover);
    }
  }
}
