.present-form {
  background-color: var(--primary-dark-color);
  border-radius: var(--radius);
  padding: 56px;
  text-align: center;
  color: var(--white);
  max-width: 820px;
  margin: 0 auto;

  &.small {
    padding: 32px 56px;
    max-width: 630px;
    @include breakpoint(small down) {
      padding: 32px 24px;
    }
  }

  form {
    max-width: 530px;
    margin: 0 auto;
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;

    @include breakpoint(small down) {
      margin-bottom: 16px;
      gap: 10px;
    }
  }

  h3 {
    margin-bottom: 24px;
  }

  h4 {
    font-family: var(--body-font-family);
  }

  input {
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);

    &::placeholder {
      color: var(--medium-grey);
    }

    @include breakpoint(small down) {
      font-size: 14px;
    }
  }

  fieldset {
    border: none;
    padding: 0;
    justify-content: space-between;
    flex-wrap: wrap;
    display: none;

    &.show {
      display: flex;
    }

    &.box {
      justify-content: flex-start;
      gap: 4px;
    }

    label {
      max-width: 240px;
      margin-bottom: 0;

      @include breakpoint(small down) {
        max-width: 100%;
        width: 100%;
      }

      &:last-child {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }

  .button {
    width: 207px;
    height: 48px;
    font-size: 20px;
    font-family: var(--title-font-family);
    font-weight: 800;
    padding: 16px;
    margin-top: 8px;
    @include breakpoint(small down) {
      width: 100%;
    }
  }

  button.js-select-btn {
    color: var(--black);
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 400;
    border-radius: var(--radius);
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    @include breakpoint(small down) {
      font-size: 14px;
    }
  }

  &-sizes {
    display: none;

    + label {
      background-color: var(--white);
      border-radius: 4px;
      padding: 4px;
      text-transform: uppercase;
      border: 1px solid var(--light-grey);
      color: var(--black);
      cursor: pointer;
    }

    &:checked + label {
      border-color: var(--primary-color);
      background-color: var(--light-grey);
    }
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    text-align: start;
    margin-bottom: 32px;
    @include breakpoint(small down) {
      grid-template-columns: repeat(1, 100%);
      gap: 16px;
    }
  }

  &-card {
    background-color: var(--white);
    color: var(--black);
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.07);
    border-radius: var(--radius);
    overflow: hidden;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 11px;

    img {
      margin: 0 auto;
    }

    label {
      margin-bottom: 0;
    }

    &-name {
      font-weight: 700;
      font-size: 17px;
    }

    &-size {
      color: var(--medium-grey);
      font-size: 12px;
      margin: 11px 0;

      span {
        color: var(--black);
      }
    }
  }

  &-popup {
    min-width: 100%;

    button {
      font-size: 12px;
      color: var(--secondary-color);
    }

    &-window {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: none;
      justify-content: center;
      align-items: center;

      &.open {
        display: flex;
      }

      div {
        position: relative;
        padding: 56px;
        border-radius: var(--radius);
        background: var(--white);
        max-height: 80vh;
        max-width: 80vw;
        display: flex;
        justify-content: center;
        @include breakpoint(small down) {
          padding: 16px;
        }

        img {
          max-height: 100%;
          object-fit: contain;
          display: block;
        }

        button {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(50%, -50%);
          @include breakpoint(small down) {
            width: 32px;
            height: 32px;
            padding: 4px;
          }
        }
      }
    }
  }
}
