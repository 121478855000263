.pag {
  max-width: 1074px;
  margin: 0 auto;
  &-list {
    list-style: none;
    padding: 0 0 0 40px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include breakpoint(medium down) {
      padding: 0 0 0 35px;
    }
    @include breakpoint(small down) {
      padding: 0;
    }
  }
  &-item {
    padding: 0 8px;
    cursor: pointer;
    color: var(--medium-grey);
    font-weight: 700;
    transition: all var(--speed-fast);
    &.is-active {
      color: var(--primary-color);
    }
    &:hover {
      color: var(--primary-color);
      svg {
        fill: var(--primary-color);
      }
    }
    svg {
      fill: var(--medium-grey);
      margin-top: 5px;
    }
  }
}
